<template>
    <div class="action-noti">
        <div class="noti-title" v-html="title"/>
        <div v-if="content.body" class="noti-body" v-html="content.body"/>
        <div v-if="content.action"
             class="noti-action"
             :class="{'action_done': content.action_done}"
             v-html="cta"
             @click="action"/>
    </div>
</template>
<script>
import { isStackRoute } from '@/router/stack-router'

export default {
    name: 'ActionNoti',
    props: ['message'],
    mounted() {
        this.$bus.$on('notiActionDone', (messageId) => {
            if (this.message.id === messageId) {
                const temp = this.$mustParse(this.message.content)
                temp.action_done = true
                this.$set(this.message, 'content', JSON.stringify(temp))
            }
        })
    },
    beforeDestroy() {
        this.$bus.$off('notiActionDone')
    },
    computed: {
        content() {
            return this.$mustParse(this.message.content) || {}
        },
        title() {
            return this.content.msg
        },
        cta() {
            return this.content.cta || '클릭'
        },
    },
    methods: {
        action() {
            if (this.content.action_done) {
            } else {
                isStackRoute(this.content.action)
                    ? this.$stackRouter.push({
                        name: this.content.action,
                        props: { message_id: this.message.id},
                    })
                    : this.$router.push({name: this.content.action})
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.action-noti {
    padding: 5px 12px;

    .noti-title {
        font-size: 14px;
        color: black;
        padding: 0 2px;
    }
    .noti-body {
        font-size: 14px;
        margin-top: 12px;
        color: black;
        padding: 0 2px;
    }
    .noti-tail {
        font-size: 12px;
        margin-top: 8px;
        color: $grey-07;
        padding: 0 2px;
    }
    .noti-action {
        width: 100%;
        height: 32px;
        font-size: 14px;
        color: white;
        margin-top: 14px;
        border-radius: 8px;
        background: $purple-primary;
        display: flex;
        justify-content: center;
        align-items: center;

        &.action_done {
            background: $grey-02;
            color: $grey-05;
        }
    }
}
</style>
